<template>
  <div>
    <div class="mb-1">
      <b-modal id="update-user-password-modal" title="Change User Password " size="md" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" enctype="multipart/form-data" files="true" role="form">
          <div class="modal-content bg-white rounded">
            <div class="form-row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="name" class="col-form-label col-form-label-sm">New Password <span class="custom-required">*</span></label>
                <input type="password" v-model="newPassword" v-validate="'required'" id="newPassword" name="new_password" class="form-control form-control-sm" placeholder="Enter new password"/>
                <div class="invalid-feedback">New password is required</div>
              </div>
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="name" class="col-form-label col-form-label-sm">Re-enter New Password <span class="custom-required">*</span></label>
                <input type="password" v-model="confirmPassword" v-validate="'required'" id="confirmPassword" name="confirm_password" class="form-control form-control-sm" placeholder="Re-enter new password"/>
                <div class="invalid-feedback">New password confirmation is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update Password</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('update-user-password-modal')">Cancel</a-button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'updateUserPassword',
  data() {
    return {
      userId: '',
      newPassword: '',
      confirmPassword: '',
      validation_errors: {},
      loader: false,
      show: false,
    }
  },
  methods: {
    onUpdate(userId) {
      this.loading = true
      this.userId = userId
      this.newPassword = ''
      this.confirmPassword = ''
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('new_password', this.newPassword)
          formData.append('confirm_password', this.confirmPassword)
          apiClient.post('api/user/update/password/' + this.userId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#user_list').DataTable().destroy()
                this.$bvModal.hide('update-user-password-modal')
                this.$emit('getUsers')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Failed to Change Password',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .multiselect__tags {
    border-color: #ced4da;
    border-radius: 3px;
    min-height: 31px;
    padding: 4px 25px 0px 10px;
    margin-bottom: 0;
  }
  .multiselect__tag {
    margin-bottom: 0px;
  }
  .multiselect__placeholder {
    margin-bottom: 0px;
  }
  .multiselect__select {
    height: 31px;
  }
</style>
