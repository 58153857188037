<template>
  <div>
    <div class="mb-1">
      <b-modal id="add-user-modal" title="User Add" size="xl" hide-footer  @hidden="onHidden">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" enctype="multipart/form-data" files="true" role="form">
          <div class="modal-content bg-white rounded">
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
                <input type="text" v-model="name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter user name"/>
                <div class="invalid-feedback">Name is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="email" class="col-form-label col-form-label-sm">Email <span class="custom-required">*</span></label>
                <input type="text" v-model="email" v-validate="'required'" id="email" name="email" class="form-control form-control-sm" placeholder="Enter email address"/>
                <div class="invalid-feedback">Email is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="phone_no" class="col-form-label col-form-label-sm">Phone Number <span class="custom-required">*</span></label>
                <input type="text" v-model="phone_no" v-validate="{ regex: /^[0-9]*$/ }" id="phone_no" name="phone_no" class="form-control form-control-sm" placeholder="Enter phone number"/>
                <div class="invalid-feedback">Phone number is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="office_id" class="col-form-label col-form-label-sm">Office ID</label>
                <input type="text" v-model="office_id" id="office_id" name="office_id" class="form-control form-control-sm" placeholder="Enter office id"/>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="warehouse_id" class="col-form-label col-form-label-sm">Warehouse Name <span class="custom-required">* If user warehouse in charge, please select warehouse from dropdown</span></label>
                <select v-model="warehouse_id" id="warehouse_id" name="warehouse_id" class="form-control form-control-sm">
                  <option selected :value="''">Select a warehouse</option>
                  <option v-for="(warehouse, index) in warehouses" :key="index" :value="warehouse.id">{{ warehouse.name }}</option>
                </select>
                <div class="invalid-feedback">Warehouse name is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hub_id" class="col-form-label col-form-label-sm">Hub Name <span class="custom-required">* If user hub in charge, please select hub from dropdown</span></label>
                <select v-model="hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                  <option selected :value="''">Select a hub</option>
                  <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
                </select>
                <div class="invalid-feedback">Hub name is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="is_active" class="col-form-label col-form-label-sm">Account Type <span class="custom-required">*</span></label>
                <select v-model="is_active" v-validate="{ required: true }" id="is_active" name="is_active" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select a account type</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <div class="invalid-feedback">Account type is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="roles" class="col-form-label col-form-label-sm">Roles <span class="custom-required">*</span></label>
                <multiselect ref="roles" v-model="selected_roles" v-validate="{ required: true }" id="roles" name="roles" :multiple="true" :clear-on-select="false" :preserve-search="true" placeholder="Select roles" track-by="id" :preselect-first="false" :options="roles" :custom-label="customRoles"></multiselect>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('roles')">Please select an role</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="profile_picture" class="col-form-label col-form-label-sm">Profile Picture <span class="custom-required">*</span></label>
                <input type="file" ref="profilePicture" v-validate="'required'" id="profile_picture" name="profile_picture" @change="getProfilePicture()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Profile picture is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('add-user-modal')">Cancel</a-button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import Multiselect from 'vue-multiselect'
import $ from 'jquery'

export default {
  name: 'addUser',
  components: { Multiselect },
  data() {
    return {
      name: '',
      email: '',
      phone_no: '',
      office_id: '',
      warehouse_id: '',
      hub_id: '',
      is_active: '',
      profile_picture: '',
      selected_roles: [],
      roles: [],
      hubs: [],
      warehouses: [],
      validation_errors: '',
      show: false,
      loader: false,
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    customRoles(option) {
      return `${option.name}`
    },
    getCode() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.roles = data.roles
          this.hubs = data.hubs
          this.warehouses = data.warehouses
        })
        .catch(error => {
          console.log(error)
        })
    },
    getProfilePicture() {
      this.profile_picture = this.$refs.profilePicture.files[0]
      if (this.profile_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.profile_picture = ''
      }
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('email', this.email)
          formData.append('phone_no', this.phone_no)
          formData.append('office_id', this.office_id)
          formData.append('warehouse_id', this.warehouse_id)
          formData.append('hub_id', this.hub_id)
          formData.append('is_active', this.is_active)
          formData.append('profile_picture', this.profile_picture)
          formData.append('roles', JSON.stringify(this.selected_roles))
          apiClient.post('api/users', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            if (!response.data.error) {
              this.$notification.success({
                message: response.data.message,
              })
              this.$bvModal.hide('add-user-modal')
              $('#user_list').DataTable().destroy()
              this.resetFormData()
              this.$emit('getUsers')
            }
          }).catch((error) => {
            this.loader = false
            this.show = true
            this.validation_errors = error.response.data.errors
            this.hide()
          })
        }
      })
    },
    resetFormData() {
      this.name = ''
      this.email = ''
      this.phone_no = ''
      this.office_id = ''
      this.warehouse_id = ''
      this.hub_id = ''
      this.is_active = ''
      this.profile_picture = ''
      this.selected_roles = []
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    onHidden () {
      this.user = {}
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .multiselect__tags {
    border-color: #ced4da;
    border-radius: 3px;
    min-height: 31px;
    padding: 4px 25px 0px 10px;
    margin-bottom: 0;
  }
  .multiselect__tag {
    margin-bottom: 0px;
  }
  .multiselect__placeholder {
    margin-bottom: 0px;
  }
  .multiselect__select {
    height: 31px;
  }
</style>
